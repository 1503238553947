(function () {
    "use strict";

    var $document = $(document),
        $window = $(window),
        plugins = {},
        setPath = window.mainPath ? window.mainPath : '';

    $document.ready(function () {
        console.log('All resources loaded.');

        plugins.home = $('.container-home');
        plugins.canvas = document.getElementById('canvas');
        plugins.navbar = $('.navbar-toggler');
        plugins.dropdownMenu = $('span.dropdown-toggle');
        plugins.cardImg = $('.card-img');
        // plugins.notAudio = $('.not-audio');
        // plugins.upButton = $('.top-scroll');
        // plugins.plyr = document.querySelectorAll('.plyr-player');
        plugins.owl = $("[data-owl='text']");
        plugins.owlProduct = $("[data-owl='product']");
        plugins.lightGalleryItem = $("[data-lightgallery='item']");
        plugins.lightGallerySelect = $("[data-lightgallery='select']");
        plugins.stereoGallery = document.querySelectorAll('.stereo-gallery');
        plugins.threedGallery = document.querySelectorAll('.threed-gallery');

        // Плавное появление
        if (plugins.home.length) {
            plugins.home.fadeIn(2000);
        }

        // Добавление stereoAnimImage
        if (plugins.canvas) {
            stereoAnimImage(plugins.canvas);

            setTimeout(function() {
                $('.sun-cosmodreams').addClass('active');
            }, 1000);

            // Затемнение заднего фона.
            $('.sun-cosmodreams').hover(
                function () {
                    $('.home-slider').addClass('example');  // Добавляем класс bounce
                },
                function () {
                    $('.home-slider').removeClass('example');  // Убираем класс bounce
                }
            );

            // Затемнение надписи
            $('.title-cosmodreams').hover(
                function () {
                    $(this).addClass('active');  // Добавляем класс bounce
                },
                function () {
                    $(this).removeClass('active');  // Убираем класс bounce
                }
            );
        }

        if (plugins.navbar.length) {
            // Плавное открытие меню.
            plugins.navbar.click(function () {
                $(this).toggleClass("open");
                $('.navbar-fix').toggleClass('btnactive');
                $('.navitem').toggleClass('btnactive');

                $('.navitem li').each(function (i) {
                    setTimeout(function () {
                        $('.navitem li.navlink').eq(i).toggleClass('navactive');
                    }, 100 * (i + 1));
                });
            });

            // Фикс высоты навигации
            heightNavbar();

            $window.on('resize', function () {
                heightNavbar();
            });

            // Закрытие меню при нажатие на ссылку.
            $('.navlink a').click(function () {
                plugins.navbar.removeClass("open");
                $('.navbar-fix').removeClass('btnactive');
                $('.navitem').removeClass('btnactive');

                $('.navitem li').each(function (i) {
                    $('.navitem li.navlink').eq(i).removeClass('navactive');
                });
            });

            // Затемнение пунктов меню
            $('.navlink').hover(
                function () {
                    $('.nav-link-btn').addClass('active');  // Добавляем класс bounce
                },
                function () {
                    $('.nav-link-btn').removeClass('active');  // Убираем класс bounce
                }
            );
        }

        // Выподающее подменю
        if (plugins.dropdownMenu.length) {
            plugins.dropdownMenu.on('click', function () {
                var $el = $(this);

                if (!$el.next().hasClass('show')) {
                    if ($el.parents('.dropdown-menu').length) {
                        $el.parents('.dropdown-menu').find('.show').removeClass("show");
                    } else {
                        $el.parents('.navbar-collapse').find('.show').removeClass("show");
                    }
                }

                $el.next(".dropdown-menu").toggleClass('show');

                $el.parents("li").first().toggleClass('show');
            });

            $window.on('resize', function () {
                if ($window.width() <= 992) {
                    plugins.dropdownMenu.next(".dropdown-menu").removeClass("show");

                    plugins.dropdownMenu.parents("li").removeClass("show");
                }
            });
        }

        // Фикс высоты изображение
        if (plugins.cardImg.length && !(plugins.owl.length || plugins.owlProduct.length)) {
            heightImg(plugins.cardImg);
        }

        // Кнопка вверх.
        // if (plugins.upButton.length) {
        //     var topBlock = $('html');

        //     plugins.upButton.click(function () {
        //         topBlock.animate({ scrollTop: 0 }, 500);
        //     });

        //     $window.scroll(function () {
        //         if ($(this).scrollTop() > 300) {
        //             plugins.upButton.fadeIn();
        //         } else {
        //             plugins.upButton.fadeOut();
        //         }
        //     });
        // }

        // Добавление Plyr плеера
        // if (plugins.plyr.length) {
        //     for (var i = 0; i < plugins.plyr.length; i++) {
        //         new Plyr(plugins.plyr[i]);
        //     }
        // }

        // Добавление BackgroundAudio
        // if (!plugins.notAudio.length) {
        //     new BackgroundAudio({
        //             btnPlay: '.btn-music',
        //             videoHTML: '.plyr',
        //             btnOn: setPath + 'img/equalizer-on.png',
        //             btnOff: setPath + 'img/equalizer-off.png',
        //             autoplay: true,
        //             html5: false
        //         }, [
        //             {
        //                 src: [setPath + 'audio/kosmos-polnostyu.mp3']
        //             }
        //         ]
        //     );
        // }

        // Добавление owl карусели
        if (plugins.owl.length) {
            for (var i = 0; i < plugins.owl.length; i++) {
                initOwlCarousel(plugins.owl[i]);
            }

            heightImg(plugins.owl.find('.card-img'));
        }

        if (plugins.owlProduct.length) {
            for (var i = 0; i < plugins.owlProduct.length; i++) {
                initOwlProduct(plugins.owlProduct[i]);
            }

            heightImg(plugins.owlProduct.find('.card-img'));
        }

        // Добавление lightGallery
        if (plugins.lightGallerySelect.length) {
            for (var i = 0; i < plugins.lightGallerySelect.length; i++) {
                initLightGallerySelect(plugins.lightGallerySelect[i], i);
            }
        }

        if (plugins.lightGalleryItem.length) {
            var notCarouselItems = [];
            for (var z = 0; z < plugins.lightGalleryItem.length; z++) {
                notCarouselItems.push(plugins.lightGalleryItem[z]);
            }

            plugins.lightGalleryItem = notCarouselItems;
            for (var i = 0; i < plugins.lightGalleryItem.length; i++) {
                initLightGalleryItem(plugins.lightGalleryItem[i]);
            }
        }

        // Добавление stereoGallery
        if (plugins.stereoGallery.length) {
            for (var i = 0; i < plugins.stereoGallery.length; i++) {
                initStereoGallery(plugins.stereoGallery[i]);
            }
        }

        // Добавление threedGallery
        if (plugins.threedGallery.length) {
            for (var i = 0; i < plugins.threedGallery.length; i++) {
                initThreedGallery(plugins.threedGallery[i]);
            }
        }
    });

    // Высота навигации
    function heightNavbar() {
        $('.navbar-fix').css({
            height: $window.height() - 140 + 'px'
        });
    }

    // Высота изображений
    function heightImg(items) {
        items.height(items.width() / 1);
    }

    // Инициализация owl карусели
    function initOwlCarousel(itemsToInit) {
        $(itemsToInit).owlCarousel({
            items: 1,
   
            margin: 10,
            nav: true,
            loop: true,
            center: true,
 
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    stagePadding: 80,
                    items: 1
                },
                992: {
                    stagePadding: 180,
                    items: 1
                },
                1200: {
                    margin: 10,
                    stagePadding: 260,
                    items: 1
                }
            }
        });
    }

    function initOwlProduct(itemsToInit) {
        $(itemsToInit).owlCarousel({
            items: 1,
   
            margin: 10,
            nav: true,
            loop: false,
            center: true,
 
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    stagePadding: 0,
                    items: 1
                },
                992: {
                    stagePadding: 0,
                    items: 1
                },
                1200: {
                    margin: 10,
                    stagePadding: 100,
                    items: 1
                }
            }
        });
    }

    function initLightGallerySelect(itemToInit, id) {
        $(itemToInit).lightGallery({
            download: false,
            selector: $(itemToInit).data('lg-select'),
            galleryId: id + 1,
            subHtmlSelectorRelative: true
        });
    }

    function initLightGalleryItem(itemToInit) {
        $(itemToInit).lightGallery({
            selector: "this",
            download: false,
            counter: false,
            subHtmlSelectorRelative: true
        }); 
    }

    function initStereoGallery(itemToInit) {
        stereoGallery(itemToInit, {
                selector: itemToInit.dataset.sgSelect,
                thresholdX: 15,
                thresholdY: 40
            }
        );
    }

    function initThreedGallery(itemToInit) {
        threedGallery(itemToInit, {
                selector: itemToInit.dataset.tdgSelect,
                HDRTexture: setPath + 'img/textures/skyBox/studio_small_06_1k.hdr',
                imgMove: setPath + 'img/001-move.png',
                imgMinimize: setPath + 'img/003-minimize.png',
                imgSlide: setPath + 'img/002-slide.png'
            }
        );
    }
}());